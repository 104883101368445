.transition-all {
  transition-property: all;
}

.transition-transform {
  transition-property: transform;
}

.duration-200 {
  transition-duration: 200ms;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.translate-x-0 {
  transform: translateX(0);
}

.translate-x-100 {
  transform: translateX(100%);
}
